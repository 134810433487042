.UplodeFile-folder {
    border: 1px solid #0068FF;
    display: flex;
    align-items: center;
    gap: 13px;
    padding: 8px 12px;
    border-radius: 8px;
    width: 200px;
    justify-content: center;
    position: relative;
    cursor: pointer;
}
.UplodeFile-folder i{
    color: #0068FF;
    margin-right: 10px;
}
.UplodeFile-folder label {
    display: inline-flex;
    color: #0068FF;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 400;
    cursor: pointer;
    align-items: center;
}
.assignment-uplode-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.assignment-view-folder {
    display: flex;
    align-items: baseline;
    gap: 9px;
    cursor: pointer;
}
.assignment-view-folder p{
    color: #0068FF;
    font-size: 13px;
}
.UplodeFile-folder input{
    z-index: 2;
    position: absolute;
    opacity: 0;
    width: 173px;
}

@media only screen and (max-width: 767px){
    .UplodeFile-folder {
        display: inline-flex;
        align-items: center;
        gap: 13px;
        padding: 8px 9px;
        width: 153px;
        justify-content: center;
    }
    .assignment-uplode-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .assignment-view-folder {
        margin: 6px 0px 0px auto;
    }
    .UplodeFile-folder label {
        font-size: 10px;
    }
    .UplodeFile-folder i {
        font-size: 13px;
    }
}
  
  .UplodeFile-folder input[type="file"] {
    display: none;
  }
  .circular-progress {
    margin-left: 10px;
  }
  .popup-close-btn{
    background:#0068FF;
    color: white;
    width: 70px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #0068FF;
  }

  .math-content {
    font-family: 'Times New Roman', Times, serif;
    line-height: 1.6;
    font-size: 16px;
    }

    .math-content p {
        margin-top: 20px;
        margin-bottom: 5px;
    }
