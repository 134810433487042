button.add-profile-save-btn {
    border: 1px solid;
    padding: 8px 67px;
    background-color: #0068ff;
    color: white;
    font-size: 13px;
    border-radius: 5px;
    margin: 30px 0px 0px 0px;
}
.add-profile-close-btn {
    color: black !important;
    background-color: transparent !important;
}
.add-profile-btn-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 0px auto;
}
#AddProfilepopup .dashboard-popup-form-wrapper .dashboard-popup-teacher-profile-details-form .Create-Lesson-section .view-lessons-section{
    margin: 0px;
}
#AddProfilepopup .dashboard-popup-form-wrapper .dashboard-popup-teacher-profile-details-form .Create-Lesson-section .view-lessons-section .view-lessons-btn-container {
    margin-bottom: 30px;
}
#AddProfilepopup .dashboard-popup-form-wrapper .dashboard-popup-teacher-profile-details-form .Create-Lesson-section .view-lessons-section .tab-content .dashboard-popup-teacher-profile-details-form-container .dashboard-popup-teacher-profile-details-input{
    width: 100%;
}
#add-profile-form .dashboard-popup-teacher-profile-details-input .form-icon{
    position: absolute;
    top: 40px;
    left: 2.6%;
}