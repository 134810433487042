.course-inner-info-wrapper {
    display: flex;
    gap: 12px;
    margin: 15px 0px 0px 0px;
    position: absolute;
    bottom: 14px;
}
.course-inner-info {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    cursor: pointer;
}

.course-inner-without-info{
    display: none;
}

.course-inner-info-with-image{
    width: 32px;
    height: 33px;
    display: block;
}
.course-inner-info-without-image{
    display: none;
}
.course-inner-info-title h2{
    font-size: 13px;
    margin: 0px;
}
.Sales-Courses-toggle-btn {
    display: flex;
    align-items: center;
    gap: 11px;
}
.course-location {
    display: flex;
    gap: 10px;
    align-items: center;
}
.course-location span{
    font-size: 18px;
}
.course-location p{
    font-size: 13px;
    color: black;
}
.course_inner_tab_details{
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: 10px 0px 0px 0px;
}
.course-inner-title-wrapper {
    display: flex;
    align-items: center;
    gap: 19px;
}
.school_title {
    width: auto !important;
    display: flex;
    gap: 15px;
}
