.Dashboard-Review-Card-foler {
    width: 268px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 2px 11px #d9d9d9;
}
.Dashboard-Review-Card-teacher-id-folder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Teacher_ID{
    font-size: 14px;
    color: black;
    cursor: pointer;
}
.teacher_Grade {
    font-size: 14px;
    color: black;
    margin: 5px 0px 0px 0px;
    font-weight: 500;
}
.Dashboard-Review-Card-image-folder {
    text-align: center;
    position: relative;
}
.Dashboard-Review-Card-image {
    width: 75px;
    height: 75px;
    margin: 18px auto;
    cursor: pointer;
}
.Dashboard-Review-Card-image img{
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
}
.Dashboard-Review-Card-image-aword {
    position: absolute;
    top: 0%;
    left: 59px;
    right: 0%;
}
.Dashboard-Review-Card-foler h2{
    text-align: center;
    font-size: 19px;
    color: #303972;
    font-weight: 700;
    margin: 0px 0px 5px 0px;
    cursor: pointer;
}
.teacher-reviews {
    display: flex;
    align-items: baseline;
    gap: 4px;
    justify-content: center;
}
.teacher-reviews span, 
.teacher-reviews p{
    font-size: 14px;
}
.Dashboard-Review-subject-folder {
    display: flex;
    flex-wrap: wrap;
    margin: 14px 0px;
    height: 57px;
    overflow: hidden;
}
.Dashboard-Review-subject-folder p {
    font-size: 11px;
    border-radius: 20px;
    padding: 4px 9px;
    background-color: #CFE5FB;
    color: black;
    text-align: center;
    height: 24.6px;
}
.Dashboard-Review-Card-button{
    display: flex;
    margin: 0px auto;
    background-color: transparent;
    border: none;
    color: #0068FF;
    font-size: 15px;
}
.Dashboard-Review-Card-wrapper {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 20px;
    gap: 25px;
    margin-bottom: 20px;
}