.Overview-Lesson-downlode-btn, 
.Overview-Lesson-downlode-pdf-btn{
    cursor: pointer;
}
.Overview-Lesson-downlode-btn span, 
.Overview-Lesson-downlode-pdf-btn span {
    font-size: 14px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0068ff;
    color: white;
}

.pdf-file-container {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 1px 7px #e4e4e4b8;
}
.overview-lesson-start-assignment-btn{
    background-color: #cccccc !important;
    color: #666666;
    cursor: not-allowed;
    font-size: 13px;
}