.view-lessons-btn-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.tab-button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
    position: relative;
}

.tab-button.active {
    color: blue;
}

.lesson-assignment-analyticsTab-wrapper .view-lessons-wrapper .teacehr-tab-setion .dropdown-container {
    position: relative;
    width: auto;
}

.dropdown-icon {
    margin-left: 5px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    z-index: 1000;
    width: 150px;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    width: 100%;
}

.dropdown-item:hover {
    background: #f0f0f0;
}

.lesson-assignment-analyticsTab-wrapper .view-lessons-wrapper .tab-content {
    margin-top: 20px;
}
.lesson-assignment-analyticsTab-dropdown-menu {
    padding: 10px 0px;
    width: 289px;
    position: absolute;
    background-color: white;
    box-shadow: 0px 2px 9px #e0e0e0;
    border-radius: 7px;
    z-index: 2;
    max-height: 265px;
    overflow-y: scroll;
}
.lesson-assignment-analyticsTab-dropdown-menu .dropdown-item {
    padding: 10px 10px !important;
    font-size: 13px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
    margin: 5px 0 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    color: black;
}
.lesson-assignment-analyticsTab-dropdown-menu .dropdown-item:hover{
    background-color: #0068FF;
    color: white;
}

@media only screen and (max-width: 768px){
    .lesson-assignment-analyticsTab-dropdown-menu .dropdown-item {
        padding: 6px 10px !important;
        font-size: 11px;
        margin: 5px 0 0px !important;
    }
    .lesson-assignment-analyticsTab-dropdown-menu {
        width: 200px;
    }
}