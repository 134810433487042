.Select-files-popup-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000026;
  z-index: 113;
}

.select-Files-allow-download-popup-container {
  background-color: white;
  width: 425px;
  border-radius: 13px;
  position: relative;
}

.select-Files-allow-download-popup-close-btn {
  position: absolute;
  top: -58px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 33px;
  font-size: 15px;
  cursor: pointer;
}

.select-assignments-allow-lesson-preview-popup-container h2,
.select-Files-allow-download-popup-container h2 {
  font-size: 18px;
  padding: 18px;
  border-bottom: 1px solid #E3E3E3;
}

.select-Files-allow-download-inner-popup-folder {
  padding: 0px 40px 30px 40px;
}

.drag-file-area {
  border: 2px dashed #BBBBBB;
  border-radius: 7px;
  margin: 10px 0 19px 0px;
  padding: 11px 21px;
  width: 350px;
  text-align: center;
  position: relative;
  font-size: 26px;
  margin: 15px 0;
}

.material_symbols_light_upload {
  width: 33px;
  margin: 0px auto 13px auto;
}

.drag-file-here-text {
  font-size: 16px !important;
  border: none;
  padding: 0px !important;
  margin: 0px 0px 5px 0px;
  color: #C8C8C8;
  font-weight: 300;
  border: none !important;
}

.material-icons-outlined {
  font-size: 12px;
  color: #C8C8C8;
}

.select-assignments-allow-lesson-preview-btn-folder {
  display: flex;
  gap: 10px;
}

.select-assignments-allow-lesson-preview-btn-folder button {
  width: 50%;
  background-color: transparent;
  color: #2E83FF;
  border: 2px solid #2E83FF;
  font-size: 15px;
  padding: 8px 0px;
  border-radius: 7px;
  font-weight: 500;
}

.select-assignments-allow-lesson-preview-btn-folder button:nth-child(2) {
  width: 50%;
  background-color: #2E83FF;
  color: white;
}

.select-Files-allow-download-inner-popup-folder p {
  text-align: center;
  /* font-size: 19px; */
  font-size: 14px;
  color: #C8C8C8;
  margin: 0px 0px 14px 0px;
}

.connect-google-drive-btn {
  border-radius: 33px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 84%;
  margin: 0px auto;
  box-shadow: 0px 1px 8px #efefef;
  cursor: pointer;
}

.connect-google-drive-btn p {
  color: black;
  font-size: 12px;
  margin: 0px;
}

.google-uplode-popup-wrapper .select-Files-allow-download-inner-popup-folder {
  padding: 30px 40px 30px 40px;
}

.default-file-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
}

.drag-file-here-text .Browse {
  color: #2E83FF;
  font-weight: 400;
}

.select-Files-allow-download-popup-scroller {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin: 0px 0px 22px 0px;
  border: 1px;
  max-height: 130px;
  overflow-y: auto;
}

.select-assignments {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-assignments-allow-lesson-preview-scroller .select-assignments input {
  width: 13px;
  height: 13px;
}

.select-assignments-allow-lesson-preview-scroller .select-assignments p {
  font-size: 14px;
  color: black;
}

.downlode-pdf-folder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px 8px 0px 0px;
}

.downlode-pdf-folder .downlode-pdf-cancle-btn {
  font-size: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 40px;
  cursor: pointer;
}

.downlode-pdf-inner-folder {
  display: flex;
  align-items: center;
  gap: 9px;
}

.downlode-pdf-image {
  width: 34px;
}

.downlode-pdf-image img {
  width: 100%;
}

.downlode-pdf-title-folder h2 {
  padding: 0px;
  border: none;
  margin: 0px 0px 2px 0px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 13px;
  color: black;
  width: 258px;
}

.downlode-pdf-title-folder p {
  font-size: 10px !important;
  color: #AEAEAE !important;
  text-align: left;
  margin: 0px;
}

.downlode-pdf-title-folder a span h2 img {
  width: 34px;
  height: 34px;
}

.downlode-pdf-title-folder {
  display: flex;
  align-items: center;
  gap: 9px;
}
.downlode-pdf-folder .loading_spiner span{
  width: 28px !important;
  height: 28px !important;
}