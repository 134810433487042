.maltiple-assignment-card-folder {
    border-radius: 7px;
    box-shadow: 0 1px 7px #cfcfcf;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 27px;
}
.maltiple-assignment-card {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: space-between;
}
.maltiple-assignment-title {
    font-size: 13px;
    color: black;
    font-weight: 500;
    width: 224px;
}
.maltiple-assignment-card-date-container {
    align-items: center;
    display: flex;
    gap: 42px;
    justify-content: flex-start;
    width: 426px;
}
.maltiple-assignment-card-date {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
}
.maltiple-assignment-card-date p:nth-child(1){
    color: #0FBA00;
    font-weight: 500;
}
.maltiple-assignment-card-date p{
    font-size: 13px;
    color: black;
}
.maltiple-assignment-btn {
    background-color: #2BCC00;
    color: white;
    padding: 8px 46px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    width: 201px;
}
.maltiple-assignment-start-assignment-btn {
    background-color: transparent;
    color: #0068FF;
    padding: 8px 11px;
    font-size: 11px;
    border: 2px solid #0068FF;
    border-radius: 5px;
    width: 201px;
    font-weight: 500;
}
.practice-assignment-title{
    color: #0068FF;
}
.maltiple-assignment-card-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 20px;
}
.maltiple-assignment-start-assignment-btn:focus{
    border: 2px solid #0068FF;
}
.maltiple-assignment-start-btn{
    background-color: #0068FF;
    color: white;
    padding: 8px 46px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    width: 201px;
}
.maltiple-assignment-view-result-assignment-btn{
    background-color: transparent;
    color: #2BCC00;
    padding: 8px 46px;
    font-size: 12px;
    border-radius: 5px;
    width: 201px;
    border: 2px solid #2BCC00;
}
.due-date{
    color: #F43939;
}

@media only screen and (min-width: 830px) and (max-width:  900px){
    .maltiple-assignment-btn, 
    .maltiple-assignment-start-btn {
        padding: 8px 4px;
        font-size: 11px;
        width: 139px;
    }
}

@media only screen and (max-width: 767px){
    .maltiple-assignment-card {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }
    .maltiple-assignment-title {
        width: 100%;
    }
    .maltiple-assignment-card-date-container {
        align-items: initial;
        display: flex;
        flex-direction: column;
        gap: 17px;
        justify-content: flex-start;
        width: 100%;
    }
    .maltiple-assignment-btn, 
    .maltiple-assignment-start-assignment-btn {
        width: 100%;
        font-size: 11px;
        padding: 8px 22px;
    }
    .maltiple-assignment-card-date p {
        font-size: 12px;
        color: black;
    }
    .maltiple-assignment-static-content-folder li {
        font-size: 11px;
    }
    .maltiple-assignment-start-btn {
        width: 100%;
        padding: 8px 30px;
    }
}

@media only screen and (max-width: 768px){
    .maltiple-assignment-card {
        flex-wrap: wrap;
        display: flex;
    }
    .maltiple-assignment-btn {
        width: 100%;
    }
}

@media only screen and (max-width: 820px){
    .maltiple-assignment-card {
        flex-wrap: wrap;
        display: flex;
    }
    .maltiple-assignment-btn {
        width: 100%;
    }
    .maltiple-assignment-card-date-container {
        display: flex;
        gap: 21px;
        width: 426px;
    }
    .maltiple-assignment-start-btn {
        padding: 8px 15px;
        font-size: 12px;
        width: 100%;
    }
}