.chatbot-play-pause-folder .play-pause-btn-lesson-generator {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px 0px 0px;
}

.chatbot-play-pause-folder .play-pause-btn-lesson-generator i {
    cursor: pointer;
}

.chatbot-play-pause-folder .play-pause-btn-lesson-generator #loading-bar-spinner.spinner {
    position: relative;
    margin: 1px -27px 0px 0px;
}
.chatbot-play-pause-folder .play-pause-btn-lesson-generator audio {
    height: 29px;
}
.chatbot-mic-folder .mic  {
    position: absolute;
    bottom: 18px !important;
    right: 62px !important;
}
.chatbot-mic-folder .mic i {
    position: absolute;
    left: 8px !important;
    z-index: 11;
    top: 8px !important;
    font-size: 14px !important;
    border-right: 0px !important;
    padding-right: 0px !important;
    color: black;
}
.customchatbot-text-feild{
    width: 100%;
    background-color: #f5f5f5;
}
.customchatbot-text-feild textarea{
    padding: 18px 6px 18px 50px;
    width: 77%;
    
}