.popup-wrapper {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    margin: auto;
    background-color: #35353585;
    z-index: 113;
}
.popup-container {
    max-width: 675px;
    background-color: white;
    text-align: center;
    padding: 36px 36px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%) !important;
    left: 0%;
    right: 0%;
    margin: auto;
    border-radius: 14px;
    z-index: 2;
}
.practice-assignment-popup {
    text-align: left;
}
.practice-assignment-popup h2{
    font-size: 18px;
    margin: 0px 0px 7px 0px;
}
.practice-assignment-popup h2 span{
    font-weight: 400;
}
.practice-assignment-popup p{
    font-size: 14px;
    color: #707070;
    margin: 0px 0px 2px 0px;
}
.practice-assignment-popup p span{
    font-weight: 600;
    color: black;
}
.practice-assignment-input-folder {
    display: flex;
    width: 100%;
    gap: 15px;
    margin: 15px 0px;
}
.practice-assignment-inner-input {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.practice-assignment-inner-input label{
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
}
.practice-assignment-button{
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0px auto;
    background-color: #0068FF;
    color: white;
    padding: 8px 37px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
}
.practice-assignment-inner-input .time-field {
    display: flex;
    border-radius: 6px;
    width: 100%;
    background: #F0F0F0;
    border: 1px solid #ccc;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.practice-assignment-inner-input .time-field button {
    background: white;
    border: none;
    margin: 5px;
    cursor: pointer;
    margin: 4.3px;
    border-radius: 100%;
    width: 25px;
    color: #0068FF;
    font-weight: 500;
}
.practice-assignment-close {
    position: absolute;
    top: -53px;
    left: 0px;
    right: 0px;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    background-color: white;
    font-size: 13px;
    cursor: pointer;
}
.practice-assignment-inner-input .css-1p26i56-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-size: 0.9rem;
    height: 35px;
    background: #F0F0F0;
}
.benefits-practice-assignments-inner-feature-folder {
    display: flex;
    align-items: center;
    gap: 10px;
}
.benefits-practice-assignments-image{
    width: 25px;
}
.benefits-practice-assignments-image img{
    width: 100%;
}
.benefits-practice-assignments-inner-feature-folder h2{
    font-size: 13px !important;
    margin: 0px;
}
.benefits-practice-assignments-feature-title{
    font-size: 16px !important;
}
.benefits-practice-assignments-feature-folder {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 16px;
}
.css-tlelie-MuiListItemText-root .css-10hburv-MuiTypography-root {
    font-size: 0.8rem !important;
}

@media only screen and (min-resolution: 125dpi){
    .popup-container {
        padding: 17px 20px;
    }
}

@media only screen and (max-width: 767px){
    .popup-container {
        max-width: 337px;
        padding: 18px 18px;
    }
    .practice-assignment-popup h2 {
        font-size: 14px;
    }
    .practice-assignment-popup p {
        font-size: 12px;
    }
    .practice-assignment-inner-input label {
        font-size: 12px;
    }
    .practice-assignment-button {
        padding: 8px 37px;
        font-size: 11px;
    }
    .practice-assignment-popup h2 span {
        line-height: 19px;
        font-size: 13px;
    }
    .select-assignment-type, 
    .select-difficulty-level{
        font-size: 9px !important;
        margin: 5px 0px 0px 0px !important;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .popup-container {
        max-width: 258px;
        padding: 18px 18px;
    }
    .practice-assignment-popup h2 {
        font-size: 14px;
    }
    .practice-assignment-popup p {
        font-size: 12px;
    }
    .practice-assignment-input-folder {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 9px;
        margin: 10px 0px;
    }
    .practice-assignment-inner-input {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .practice-assignment-button {
        padding: 8px 37px;
        font-size: 11px;
    }
}