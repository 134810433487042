.Conformatio-popup-section {
    /* border: 1px solid; */
    background-color: #00000030;
    padding: 30px;
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 113;
}
.Conformatio-popup-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
}
.Conformatio-popup-container {
    background-color: white;
    padding: 44px;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    border-radius: 14px;
}
.Conformatio-popup-container h2{
    margin: 0px 0px 29px 0px;
    font-size: 23px;
}
.Conformatio-popup-btn {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.Conformatio-popup-btn button{
    padding: 5px 38px;
    font-size: 15px;
    border-radius: 5px;
    background-color: transparent;
    border: 2px solid #0068FF;
    color: #0068FF;
}
.Conformatio-popup-btn button:nth-child(2){
    background-color: #0068FF;
    padding: 5px 38px;
    font-size: 15px;
    border-radius: 5px;
    border: 2px solid #0068FF;
    color: white;
}

@media only screen and (max-width: 767px){
    .Course-created-successfully-folder {
        padding: 0px;
        text-align: center;
    }
    .Conformatio-popup-container {
        max-width: 329px;
        padding: 21px;
    }
    .Conformatio-popup-container h2 {
        margin: 0px 0px 18px 0px;
        font-size: 15px;
        text-align: center;
    }
    .Conformatio-popup-btn button , 
    .Conformatio-popup-btn button:nth-child(2) {
        padding: 5px 23px;
        font-size: 12px;
        width: 50%;
    }
}