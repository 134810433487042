.preview-popup-wrapper {
    background-color: #00000061;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    padding: 30px 20px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 113;
}
.preview-popup-container {
    background-color: #fff;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    left: 0;
    margin: auto;
    max-width: 718px;
    padding: 47px 36px;
    position: fixed;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.preview-popup-close-btn {
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 0 3px #a7a7a7;
    font-size: 16px;
    padding: 5px;
    position: absolute;
    right: -17px;
    top: -12px;
    width: 34px;
}
.preview-popup-folder {
    height: 450px;
    z-index: 999;
    overflow: auto;
}
.preview-popup-folder p{
    text-align: left;
    font-size: 13px;
    color: black;
    line-height: 22px;
}
.preview-popup-close-btn{
    cursor: pointer;
}

@media only screen and (max-width: 767px){
    .preview-popup-container {
        max-width: 332px;
        padding: 22px 16px;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .preview-popup-container {
        max-width: 239px;
        padding: 22px 16px;
    }
}
.preview-popup-close-btn{
    text-align: center; 
}
/* Add this CSS to your App.css or a similar global stylesheet */
.table-latex {
    width: 100%;
    border-collapse: collapse; /* Ensures that borders between cells are merged */
    margin-top: 20px;
    margin-bottom: 20px;
}

.table-latex td, .table-latex th {
    border: 1px solid #ddd; /* Light grey border */
    padding: 8px;
    text-align: left;
}

.table-latex th {
    background-color: #f2f2f2; /* Light grey background for table headers */
    padding-top: 12px;
    padding-bottom: 12px;
}

.table-latex tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra striping for rows */
}
