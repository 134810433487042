.connected-to-schoology-profile-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    box-shadow: 0px 3px 11px #e9e9e9;
    border-radius: 6px;
}

.connected-to-schoology-profile {
    display: flex;
    align-items: center;
    gap: 12px;
}

.connected-to-schoology-profile-image {
    width: 56px;
    height: 56px;
}

.connected-to-schoology-profile-image img {
    width: 100%;
}

.connected-to-schoology-profile-details h3 {
    font-size: 15px;
    margin: 0px 0px 0px 0px;
}

.connected-to-schoology-profile-details p {
    font-size: 11px;
}

.connected-to-schoology-sync-wrapper {
    position: relative;
}

.connected-to-schoology-sync-wrapper::after {
    content: '';
    position: absolute;
    top: 3.9px;
    left: -18px;
    width: 12px;
    height: 12px;
    background-color: #28991F;
    border-radius: 100%;
}

.connected-to-schoology-sync-wrapper h2 {
    font-size: 16px;
    margin: 0px 0px 6px 0px;
}

.connected-to-schoology-sync-wrapper p {
    font-size: 11px;
    text-align: end;
    color: black;
}

.sync-with-schoology-list-container-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 30px 0px 0px 0px;
    gap: 20px;
}

.sync-with-schoology-list-container {
    width: 64%;
    padding: 16px;
    box-shadow: 0px 3px 11px #e9e9e9;
    border-radius: 6px;
    height: 62vh;
}

.sync-with-schoology-list-select-courses h2 {
    font-size: 19px;
    margin: 0px 0px 3px 0px;
}

.sync-with-schoology-list-select-courses p {
    font-size: 12px;
    max-width: 528px;
    margin: 10px 0px;
}

.sync-with-schoology-list-selected {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0px;
}

.sync-with-schoology-list-selected input {
    width: 14px;
    height: 14px;
}

.sync-with-schoology-list-selected label {
    font-size: 13px;
}

.sync-with-schoology-list-courses-folder, 
.sync-status-Summary-list-courses-folder {
    display: flex;
    align-items: flex-start;
    gap: 11px;
    border: 2px solid #D9D9D9;
    padding: 11px;
    border-radius: 9px;
}

.sync-with-schoology-list-courses-folder-name {
    width: 217px;
    height: 122px;
}

.sync-with-schoology-list-courses-folder-name img {
    width: 100%;
    height: 100%;
}

.sync-with-schoology-list-courses-folder-details, 
.sync-status-Summary-list-courses-folder-details {
    display: flex;
    width: 100%;
    text-align: left;
    justify-content: space-between;
    align-items: flex-start;
}

.sync-with-schoology-list-courses-folder-details h3, 
.sync-status-Summary-list-courses-folder-details-container h3 {
    font-size: 13px;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
}

.sync-with-schoology-list-courses-folder-details p, 
.SchoologyURL-link {
    cursor: pointer;
    color: #0068FF !important;
}

.sync-with-schoology-list-recent-sync-container,
.ready-to-sync-progress-container {
    width: 36%;
    padding: 13px;
    box-shadow: 0px 3px 11px #e9e9e9;
    border-radius: 6px;
    height: 62vh;
}

.sync-with-schoology-list-recent-sync-folder-container {
    height: 53vh;
    overflow-y: auto;
    padding: 0px 10px 0px 0px;
}

.sync-with-schoology-list-recent-sync-folder-container h6 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95%;
}

.sync-with-schoology-list-recent-sync-container span,
.ready-to-sync-progress-container span,
.sync-progress-container span {
    font-size: 16px;
    font-weight: 500;
}

.sync-with-schoology-list-recent-sync-folder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    width: 100%;
}

.sync-with-schoology-list-recent-sync-date-Success-or-faild-folder {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.sync-with-schoology-list-recent-sync-date-Success-or-faild {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.sync-with-schoology-list-recent-sync-date-with-course {
    color: #000000;
    font-size: 13px;
    margin: 6px 0px 0px 0px;
    width: 100%;
    border-bottom: 1px solid;
    padding: 0px 0px 10px 0px;
}

span.recent-sync-success-status {
    font-size: 11px;
    padding: 4px 17px;
    border-radius: 25px;
    background-color: #E6FFE5;
    color: #28991F;
}

span.recent-sync-failed-success {
    font-size: 11px;
    /* width: 78px; */
    /* width: 98px; */
    padding: 4px 17px;
    border-radius: 25px;
    background-color: #FFD6D6;
    color: #B24747;
}

span.recent-sync-pending-status {
    font-size: 11px;
    width: 78px;
    padding: 4px 17px;
    border-radius: 25px;
    background-color: rgb(239, 228, 188);
    color: rgb(240, 194, 26);
}

.sync-with-schoology-list-recent-sync-date-with-course h5,
.sync-with-schoology-list-recent-sync-date-with-course h5 span {
    font-size: 13px;
}

.sync-with-schoology-list-recent-sync-date-with-course p {
    font-size: 12px;
    color: #000000;
    margin: 4px 0px 0px 0px;
    font-weight: 300;
}

.sync-with-schoology-list-courses-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 34vh;
    overflow-y: auto;
    padding: 0px 10px 0px 0px;
}

.sync-with-schoology-list-courses-container span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sync-with-schoology-list-courses-folder input , 
.sync-status-Summary-list-courses-folder input{
    width: 16px;
    height: 16px;
    margin: 6px 0px 0px 0px;
}

.sync-with-schoology-btn-folder {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 16px 0px 6px 0px;
}

.sync-with-schoology-btn-folder .refresh-course {
    border: 2px solid #0068FF;
    background-color: transparent;
    color: #0068FF;
    font-size: 14px;
    padding: 5px 32px;
}

.sync-with-schoology-btn-folder .refresh-course i {
    margin: 0px 10px 0px 0px;
}

.sync-with-schoology-btn-folder .Next {
    background-color: #0068FF;
    color: white;
    font-size: 14px;
    padding: 5px 32px;
    border: none;
    outline: none;
}

.sync-with-schoology-list-select-courses-search input {
    background-color: #F5F5F5;
    border-radius: 31px;
    font-size: 11px;
    padding: 9px 38px;
    border: none;
}

.sync-with-schoology-list-select-courses-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sync-with-schoology-list-select-courses-search {
    position: relative;
}

.sync-with-schoology-list-select-courses-search i {
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translateY(-50%);
    font-size: 13px;
}

.ready-to-sync-progress-container p {
    font-size: 12px;
    color: #555555;
    margin: 5px 0px 0px 0px;
}

.ready-to-sync-progress-container .start-sync {
    background-color: #0068FF;
    margin-right: 10px;
    color: white;
    width: 100%;
    padding: 7px 0px;
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
    margin: 16px 0px 0px 0px;
}

.ready-to-sync-progress-container .start-sync i {
    margin-right: 10px;
}

.sync-progress-container span {
    margin: 16px 0px 10px 0px;
    display: flex;
}

.sync-progress-bar {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sync-progress-bar-fill span,
.sync-progress-bar-fill-count p {
    margin: 0px;
}

.sync-progress-bar-fill-count {
    display: flex;
    align-items: baseline;
    /* gap: 10px; */
    justify-content: space-between;
    color: #0068FF;
}

.sync-progress-bar-fill-count .count {
    margin: 0px;
    font-size: 13px;
}

.sync-progress-bar-folder {
    height: 29vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0px 10px 0px 0px;
}

.mobile-view-progress-container,
.mobile-last-sync-date,
.mobile-connected-to-schoology {
    display: none;
}
.sync-with-schoology-tab-wrapper .view-lessons-btn-container{
    margin: 10px 0px 24px 0px;
    justify-content: space-between;
}
.sync-with-schoology-list-courses-folder-details-image, 
.sync-status-Summary-list-courses-folder-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.sync-with-schoology-list-courses-folder-details-image .Synced-status-Success, 
.sync-with-schoology-list-courses-folder-details-image .Synced-status-Failed, 
.sync-status-Summary-list-courses-folder-details-container .Synced-status-Success, 
.sync-status-Summary-list-courses-folder-details-container .Synced-status-Failed{
    font-size: 11px;
    padding: 4px 17px;
    border-radius: 25px;
    background-color: #E6FFE5;
    color: #28991F;
    text-align: center;
    /* width: 81px; */
    display: flex;
    justify-content: center;
}
.sync-with-schoology-list-courses-folder-details-image .Synced-status-Success, 
.sync-with-schoology-list-courses-folder-details-image .Synced-status-Failed{
    width: 106px;
}
.sync-with-schoology-list-courses-folder-details-image .Synced-status-Failed, 
.sync-status-Summary-list-courses-folder-details-container .Synced-status-Failed{
    /* width: 104px; */
    color: #B24747;
    background-color: #FFD6D6;
}
.recent-sync-view-more-popup {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0px 0px 8px 0px;
}
.recent-sync-view-more-popup button{
    font-size: 11px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #0068FF;
    text-decoration: underline;
    font-weight: 500;
}
.sync-status-Summary-totle-folder {
    display: flex;
    gap: 21px;
}
.sync-status-Summary-popup-section {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background-color: #00000026;
    z-index: 113;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sync-status-Summary-popup-wrapper {
    background-color: white;
    width: 826px;
    padding: 17px;
    border-radius: 10px;
    position: relative;
}
.sync-status-Summary-popup h2{
    font-size: 16px;
    margin: 0px;
}
.sync-status-Summary-popup h2 span{
    font-size: 12px;
    margin: 0px 0px 0px 5px;
    color: #6B7280;
}
.sync-status-Summary-popup p{
    padding: 10px 0px 3px 0px;
    font-size: 12px;
    color: black;
    font-weight: 500;
}
.sync-status-Summary-list-courses-folder-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 38vh;
    overflow-y: auto;
    padding: 0px 10px 0px 0px;
}
.sync-status-Summary-totle-folder p{
    color: #555555;
}
.sync-status-Summary-sync-errors-detected ul{
    padding: 9px 37px;
}
.sync-status-Summary-sync-errors-detected h2 img{
    margin: -3px 10px 0px -13px;
}
.sync-status-Summary-list-courses-main-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.sync-status-Summary-list-courses-folder-details {
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.sync-status-Summary-sync-errors-detected {
    width: 100%;
    padding: 9px 28px;
    border-left: 4px solid #B91C1C;
    background-color: #FEF2F2;
}
.sync-status-Summary-sync-errors-detected ul{
    padding: 9px 26px;
}
.sync-status-Summary-sync-errors-detected h2, 
.sync-status-Summary-sync-errors-detected ul li{
    font-size: 13px;
    color: #991B1B;
}
.sync-status-Summary-sync-errors-detected ul li{
    list-style: disc;
}
.sync-status-Summary-popup-sync-now{
    margin: 19px auto 0px auto;
    display: flex;
    background-color: #0068FF;
    color: white;
    padding: 8px 27px;
    border-radius: 4px;
    border: none;
    outline: none;
    font-size: 14px;
}
.sync-status-Summary-popup .close-btn{
    position: absolute;
    top: 12px;
    right: 12px;
    width: 21px;
    height: 21px;
    border: 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 12px;
}

@media (min-width: 1300px) and (max-width: 1399px) {
    .sync-with-schoology-list-courses-container {
        min-height: 33vh;
    }

    .sync-progress-bar-folder {
        min-height: 28vh;
    }
}

@media only screen and (min-resolution: 125dpi) {
    .sync-with-schoology-list-courses-container {
        max-height: 24vh;
    }

    .sync-progress-bar-folder {
        max-height: 22vh;
    }
}


@media only screen and (max-width: 1024px) {
    .sync-with-schoology-list-container {
        height: 46vh;
    }

    .sync-with-schoology-list-recent-sync-folder-container {
        height: 42vh;
    }

    .sync-with-schoology-list-recent-sync-container,
    .ready-to-sync-progress-container {
        height: 46vh;
    }

    .sync-progress-bar-folder {
        min-height: 28vh;
    }
}

@media only screen and (min-width: 826px) and (max-width: 900px) {
    .sync-with-schoology-list-courses-container {
        height: 16vh;
    }

    .sync-with-schoology-list-recent-sync-folder-container {
        /* height: 39vh; */
        height: 47vh;
    }

    .sync-progress-bar-folder {
        height: 10vh;
    }
    .sync-with-schoology-tab-wrapper .view-lessons-btn-container button {
        padding: 3px 10px;
        font-size: 12px;
    }
    .sync-with-schoology-list-select-courses-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .sync-with-schoology-list-select-courses-search i {
        position: absolute;
        top: 51%;
        left: 4%;
        font-size: 13px;
    }
    .sync-progress-bar-folder {
        min-height: 18vh;
    }
    .sync-with-schoology-list-container {
        height: 54vh;
    }
    .sync-with-schoology-list-recent-sync-container, 
    .ready-to-sync-progress-container{
        height: 54vh;
    }
    .sync-status-Summary-popup-wrapper {
        width: 781px;
    }
}

@media only screen and (max-width: 820px){
    .sync-status-Summary-popup-wrapper {
        max-width: 773px;
    }
    .sync-with-schoology-tab-wrapper .view-lessons-btn-container button {
        padding: 3px 11px;
    }
    .sync-with-schoology-list-select-courses-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 768px) {
    .sync-with-schoology-list-select-courses-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sync-with-schoology-list-select-courses-search,
    .sync-with-schoology-list-select-courses-search input {
        width: 100%;
    }

    .sync-with-schoology-list-select-courses-search i {
        position: absolute;
        top: 50%;
        left: 4%;
        font-size: 13px;
    }

    .sync-with-schoology-list-courses-folder-name {
        width: 174px;
        height: 100px;
    }

    .sync-with-schoology-list-courses-folder-details h3 {
        font-size: 14px;
        line-height: 22px;
    }

    .sync-with-schoology-list-recent-sync-date-with-course h5,
    .sync-with-schoology-list-recent-sync-date-with-course h5 span {
        font-size: 12px;
    }

    span.recent-sync-success-status {
        font-size: 11px;
        padding: 4px 13px;
    }

    span.recent-sync-failed-success {
        font-size: 11px;
        padding: 4px 13px;
    }

    .sync-with-schoology-list-container,
    .sync-with-schoology-list-recent-sync-container,
    .ready-to-sync-progress-container {
        height: 53vh;
    }

    .sync-with-schoology-list-recent-sync-folder-container {
        height: 47vh;
    }

    .sync-progress-bar-folder {
        min-height: 29vh;
    }
    .sync-status-Summary-popup-wrapper {
        max-width: 713px;
        padding: 17px;
    }
    .sync-with-schoology-tab-wrapper .view-lessons-btn-container button {
        padding: 3px 16px;
    }
}

@media only screen and (max-width: 767px) {
    .sync-with-schoology-wrapper {
        margin: 0px 0px 0px 0px;
    }

    .sync-with-schoology-list-container-wrapper,
    .sync-with-schoology-list-select-courses-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .connected-to-schoology-profile-wrapper {
        padding: 9px;
        margin: 20px 0px 0px 0px;
    }

    .connected-to-schoology-profile {
        display: flex;
        gap: 6px;
    }

    .connected-to-schoology-profile-image {
        width: 33px;
        height: 33px;
    }

    .connected-to-schoology-profile-image img {
        border-radius: 100%;
    }

    .connected-to-schoology-profile-details h3 {
        font-size: 11px;
    }

    .connected-to-schoology-sync-wrapper h2 {
        font-size: 9px;
        margin: 0px 0px 2px 0px;
    }

    .connected-to-schoology-sync-wrapper p {
        font-size: 7px;
    }

    .connected-to-schoology-sync-wrapper::after {
        position: absolute;
        top: 2.1px;
        left: -10px;
        width: 6px;
        height: 6px;
    }

    .sync-with-schoology-list-container {
        width: 100%;
        padding: 12px;
        /* height: 49vh; */
        height: 360px;
    }

    .sync-with-schoology-list-select-courses h2,
    .ready-to-sync-progress-container span,
    .sync-progress-container span {
        font-size: 13px;
        margin: 0px 0px 0px 0px;
    }

    .sync-with-schoology-list-select-courses p {
        font-size: 10px;
        max-width: 528px;
        margin: 10px 0px;
    }

    .sync-with-schoology-list-select-courses-search {
        width: 100%;
    }

    .sync-with-schoology-list-select-courses-search input {
        font-size: 10px;
        padding: 6px 32px;
        width: 100%;
    }

    .sync-with-schoology-list-select-courses-search i {
        position: absolute;
        top: 50%;
        left: 6%;
        font-size: 10px;
    }

    .sync-with-schoology-list-selected input,
    .sync-with-schoology-list-courses-folder input {
        width: 10px;
        height: 10px;
    }

    .sync-with-schoology-list-selected label {
        font-size: 11px;
    }

    .sync-with-schoology-list-courses-container {
        display: flex;
        gap: 10px;
        /* height: 22vh; */
        height: 136px;
        padding: 0px 10px 0px 0px;
    }

    .sync-with-schoology-list-courses-folder {
        display: flex;
        align-items: flex-start;
        gap: 4px;
        padding: 9px;
    }

    .sync-with-schoology-list-courses-folder-name {
        width: 109px;
        height: 65px;
    }

    .sync-with-schoology-list-courses-folder-details h3 {
        font-size: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 126px;
    }

    .sync-with-schoology-list-courses-folder-details p i {
        font-size: 10px;
        line-height: 15px;
    }

    .sync-with-schoology-list-recent-sync-container,
    .ready-to-sync-progress-container {
        width: 100%;
    }

    .sync-with-schoology-list-recent-sync-folder {
        margin: 6px 0px;
    }

    .sync-with-schoology-list-recent-sync-date-with-course h5,
    .sync-with-schoology-list-recent-sync-date-with-course h5 span,
    .sync-with-schoology-list-recent-sync-date-with-course p {
        font-size: 11px;
    }

    .sync-with-schoology-list-recent-sync-container span {
        font-size: 9px;
    }

    .recent-sync {
        font-size: 13px !important;
    }

    .connected-to-schoology-profile-details p {
        font-size: 9px;
        margin: -6px 0px 0px 0px;
    }

    .sync-with-schoology-list-recent-sync-container {
        height: 189px;
    }

    .ready-to-sync-progress-container {
        height: 163px;
    }

    .ready-to-sync-progress-container {
        display: block;
    }

    .mobile-view-progress-container {
        display: block;
        margin: 20px 0px 20px 0px;
    }

    .sync-with-schoology-list-recent-sync-folder-container {
        height: 140px;
    }

    .sync-with-schoology-btn-folder .refresh-course {
        font-size: 12px;
        padding: 5px 6px;
        width: 60%;
    }

    .sync-with-schoology-btn-folder .Next {
        font-size: 12px;
        padding: 5px 28px;
        width: 40%;
    }

    .sync-with-schoology-list-container-wrapper {
        margin: 18px 0px 0px 0px;
    }

    .Conformatio-popup-container {
        max-width: 297px;
    }

    .Conformatio-popup-container h2 {
        margin: 0px 0px 13px 0px;
        font-size: 15px;
    }

    .Conformatio-popup-btn button,
    .Conformatio-popup-btn button:nth-child(2) {
        padding: 5px 38px;
        font-size: 11px;
    }

    .ready-to-sync-progress-container p {
        font-size: 11px;
    }

    .ready-to-sync-progress-container .start-sync {
        font-size: 12px;
        margin: 11px 0px 0px 0px;
    }

    .sync-progress-main-container {
        display: none;
    }

    .mobile-view-progress-container .sync-progress-container p {
        margin: 5px 0px 10px 0px;
        font-size: 11px;
    }

    .sync-progress-bar h5 {
        font-size: 12px;
    }

    .sync-progress-bar-fill-count p {
        font-size: 10px !important;
        margin: 0px !important;
    }

    .sync-progress-bar-fill-count .count {
        font-size: 11px;
    }

    .sync-progress-bar-folder {
        min-height: 58vh;
    }

    .desktop-last-sync-date,
    .desktop-connect-to-schoology {
        display: none;
    }
    .sync-with-schoology-tab-wrapper .view-lessons-btn-container button {
        padding: 4px 6px;
        font-size: 10px;
    }
    .sync-with-schoology-list-courses-folder-details p, .SchoologyURL-link {
        font-size: 9px;
    }
    .sync-with-schoology-list-courses-folder-details-image .Synced-status-Success{
        font-size: 9px;
        padding: 4px 17px;
    }
    .recent-sync-view-more-popup button {
        font-size: 10px;
    }
    .sync-with-schoology-tab-wrapper .tab-content {
        margin: 0px 0px 0px 0px;
    }
    span.recent-sync-failed-success {
        width: 78px;
    }
    .sync-status-Summary-popup-wrapper {
        width: 325px;
        padding: 17px;
    }
    .sync-status-Summary-popup h2 {
        font-size: 14px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;
    } 
    .sync-status-Summary-popup h2 span {
        font-size: 11px;
    }  
    .sync-status-Summary-popup p {
        font-size: 12px;
    }
    .sync-status-Summary-list-courses-folder-details {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
    .sync-status-Summary-totle-folder {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }
    .sync-status-Summary-totle-folder p {
        color: #555555;
        font-size: 11px;
        padding: 10px 0px 0px 0px;
    }
    .sync-status-Summary-sync-errors-detected {
        width: 100%;
        padding: 9px 22px;
    }
    .sync-status-Summary-sync-errors-detected h2, .sync-status-Summary-sync-errors-detected ul li {
        font-size: 10px;
    }
    .sync-status-Summary-sync-errors-detected ul {
        padding: 9px 17px;
    }
    .sync-status-Summary-list-courses-main-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    .sync-status-Summary-list-courses-folder-details-container h3 {
        font-size: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 214px;
    }
    .sync-status-Summary-sync-errors-detected h2 img {
        margin: 0px 10px 0px -17px;
        position: relative;
        top: 20px;
        width: 13px;
    }
    .sync-status-Summary-popup-sync-now {
        font-size: 10px;
    }
    .sync-status-Summary-popup .close-btn {
        width: 18px;
        height: 18px;
        font-size: 9px;
    }
}

@media (min-width: 280px) and (max-width: 320px) {}
