.defult-proifle-pic{
    background-color: rgb(229, 229, 229);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
.defult-proifle-pic i{
    color: #888888;
}