.MarketPlace-Shopping-Cart-section h2{
    font-size: 21px;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
}
.marketplace-shopping-cart-wrapper {
    display: flex;
    width: 100%;
    gap: 36px;
    margin: 0px 0px 70px 0px;
}
.marketplace-shopping-cart-left-section {
    width: 80%;
    display: flex;
    gap: 25px;
    padding: 19px;
    background-color: white;
    box-shadow: 0px 1px 14px #d5d5d5bd;
    border-radius: 10px;
}
.course-bg-image{
    width: 25%;
    height: 215px;
}
.marketplace-shopping-cart-des-wrapper {
    width: 75%;
}
.marketplace-shopping-cart-des-wrapper .delete{
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    color: #FF6363;
}
.marketplace-shopping-cart-des-wrapper h2{
    text-align: left;
    font-size: 25px;
    margin: 6px 0px 10px 0px;
    word-wrap: break-word;
    width: 740px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.marketplace-shopping-cart-des-wrapper .teacher-name{
    font-size: 16px;
    color: #0068FF;
}
.studentDashboar-Suggested-Courses-profile-rate {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 14px 0px 10px 0px;
}
.course-list-counts {
    display: flex;
    align-items: center;
    gap: 17px;
    margin: 0px 0px 11px 17px;
}
.course-list-counts li{
    font-size: 14px;
    list-style: disc;
}
.marketplace-shopping-cart-price{
    font-size: 25px;
    text-align: end !important;
    width: 100% !important;
}
.marketplace-shopping-cart-right-section {
    width: 25%;
}
.marketplace-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.marketplace-price p{
    font-size: 16px;
    color: black;
}
.marketplace-price h2{
    margin: 0px;
    font-size: 27px;
}
.marketplace-inner-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.marketplace-inner-price span{
    font-size: 13px;
    color: #24C159;
    font-weight: 500;
}
.price-checkout-btn {
    width: 100%;
    font-size: 16px;
    padding: 10px 0px;
    background-color: #0068FF;
    color: white;
    border: none;
}
.marketplace-price-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 19px;
    margin-bottom: 19px;
    border-bottom: 1px solid;
}
.cupen-Applied-btn {
    border: 1px solid #D8D8D8;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 7px;
    margin: 10px 0px 16px 0px;
}
.cupen-Applied-btn span{
    font-size: 13px;
}
.cupen-Applied-btn button{
    font-size: 14px;
    background-color: transparent;
    border: none;
    color: #0068FF;
}
.marketplace-shopping-cart-section .title{
    font-size: 24px;
    margin: 0px;
}
.marketplace-inner-price span i {
    color: black;
    cursor: pointer;
    margin-left: 6px;
}