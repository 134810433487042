.maltiple-assignment-view-popup-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000030;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 113;
}

.maltiple-assignment-view-popup-container {
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    width: 866px;
    min-height: 90%;
}

.maltiple-assignment-view-popup-header {
    display: flex;
    justify-content: space-between;
}

.maltiple-assignment-view-popup-title h2 {
    font-size: 21px;
    margin: 0px 0px 7px 0px;
}

.maltiple-assignment-view-popup-title p {
    font-size: 14px;
    color: black;
}

.maltiple-assignment-view-popup-time-folder button {
    background-color: #0068FF;
    color: white;
    font-size: 12px;
    padding: 8px 28px;
    border-radius: 6px;
    border: none;
}

p.maltiple-assignment-view-popup-date {
    font-size: 12px;
    margin: 10px 0px 0px 0px;
    color: black;
}

.maltiple-assignment-view-popup-date span {
    color: #F43939;
}

.maltiple-assignment-static-content-container p {
    font-size: 18px;
    color: black;
    font-weight: 600;
    margin-bottom: 12px;
}

.maltiple-assignment-view-popup-details {
    /* height: 491px; */
    height: 62vh;
    z-index: 999;
    overflow: auto;
    margin: 20px 0px 0px 0px;
}

.maltiple-assignment-static-content-folder li {
    font-size: 14px;
    list-style: inside;
}

.maltiple-assignment-static-content-folder {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.maltiple-assignment-view-popup-folder .Back_button {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgb(0, 104, 255);
    margin-bottom: 12px;
}

.maltiple-assignment-view-popup-folder .Back_button span {
    padding: 0px 5px;
}


@media only screen and (min-width: 830px) and (max-width: 900px) {
    .maltiple-assignment-view-popup-container {
        width: 778px;
    }
}


@media only screen and (max-width: 767px) {

    .maltiple-assignment-view-popup-container {
        width: 325px;
    }

    .maltiple-assignment-view-popup-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 14px 0px 0px 0px;
    }

    .maltiple-assignment-view-popup-title h2 {
        font-size: 15px;
    }

    .maltiple-assignment-view-popup-title p {
        font-size: 12px;
    }

    .maltiple-assignment-view-popup-time-folder {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .maltiple-assignment-view-popup-time-folder button {
        font-size: 9px;
        padding: 8px 28px;
    }

    .maltiple-assignment-view-popup-date {
        font-size: 10px !important;
    }

    .maltiple-assignment-view-popup-details {
        height: 378px;
        margin: 20px 0px 0px 0px;
    }

    .maltiple-assignment-view-popup-details p {
        font-size: 14px;
    }
}

@media only screen and (min-width: 653px) and (max-width: 720px) {
    .maltiple-assignment-view-popup-container {
        width: 603px;
    }

    .maltiple-assignment-view-popup-title h2 {
        font-size: 17px;
    }

    .maltiple-assignment-view-popup-title p {
        font-size: 12px;
    }

    .maltiple-assignment-view-popup-time-folder button {
        font-size: 10px;
    }

    .maltiple-assignment-view-popup-date {
        font-size: 11px;
    }

    .maltiple-assignment-view-popup-details {
        height: 382px;
    }

    .maltiple-assignment-view-popup-details p {
        font-size: 16px;
    }
}

@media (min-width: 280px) and (max-width: 320px) {
    .maltiple-assignment-view-popup-container {
        width: 255px;
    }

    .maltiple-assignment-view-popup-title h2 {
        font-size: 14px;
    }

    .maltiple-assignment-view-popup-time-folder button {
        font-size: 9px;
        padding: 8px 28px;
    }

    .maltiple-assignment-view-popup-details {
        height: 363px;
    }
}

@media only screen and (min-resolution: 125dpi) {
    .maltiple-assignment-view-popup-container {
        max-height: 90%;
    }
}