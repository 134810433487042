.Register-container-wrapper{
    width: 88%;
    margin: 30px auto 0px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* gap: 53px; */
    gap: 34px;
}
.welcome-to-srds-form-section .Register-container-wrapper{  
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.welcome-to-srds-form-section .login-with-Verification-code p {
    color: black;
    margin: 0px 0px 0px 0px;
}
.Register-container-wrapper h2 {
    font-size: 36px;
    margin: 8px 0px 0px 0px;
    color: #333333;
    text-align: center;
    display: flex;
    align-items: baseline;
    gap: 7px;
    justify-content: center;
    font-weight: 400;
}
.Register-container-wrapper h2 span{
    font-size: 32px;
    color: #0068FF;
    font-weight: 500;
}
.login-with-Verification-code p{
    color: #0068FF;
    font-size: 15px;
    font-weight: 500;
    margin: 16px 0px 0px 0px;
    cursor: pointer;
}
.registe-emai-verfication-section .login-with-Verification-code p{
    color: black;
}
.Already-have-an-accout-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Already-have-an-accout-wrapper .Back_button {
    margin-bottom: 12px !important;
    color: black !important;
    font-size: 14px;
}
.register-left-section {
    height: 100vh;
    width: 53%;
    flex-shrink: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.register-right-section {
    width: 55%;
}
.register-left-section .back-home-btn {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 9px;
    margin: 0px 0 0 30px;
}
.register-left-section .login-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 8px 9px;
    text-decoration: none;
    border-radius: 61px;
    box-shadow: 0px 0px 7px #e2e2e2;
    background-color: transparent;
    border: none;
    background-color: #0068FF;
}
.login-btns p {
    text-align: center;
    margin: 0px;
    color: white;
    font-size: 13px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.registe-emai-verfication-section .signup-form{
    width: 52%;
    margin: 0px auto;
}
.registe-emai-verfication-section .Register-container-wrapper {
    display: flex;
    gap: 16px;
}
.registe-emai-verfication-send-Code-btn{
    width: 30%;
    font-size: 13px;
    background-color: #0068FF;
    color: white;
    padding: 8px 0px;
    border-radius: 35px;
    margin: 9px auto 0px auto;
    border: none;
    outline: none;
}
.resend-verification-with-edit-email-folder{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.resend-verification-with-edit-email-folder button{
    color: #0068FF;
    background-color: transparent;
    font-size: 14px;
    border: none;
    outline: none;
}
.Remaning_timer{
    color: #0068FF;
    font-size: 14px !important;
}
.login-mani-wrapper{
    width: 100%;
    height: 100vh;
    position: "relative";
    display: flex;
    justify-content: center;
    align-items: center;
}
.otp-verification-section .verification_code {
    width: 66%;
}
.otp-verification-section .verification_code .css-1s8hvgy {
    gap: 17px;
}
.user-tab-selecting-folder{
    width: 52%;
    margin: 0px auto;
}
.user-tab-selecting-folder .css-1869usk-MuiFormControl-root,
.user-tab-selecting-folder .css-b1884d{
    min-width: 100%;
}

.Already-have-an-accout-folder a {
    color: #0068ff !important;
}
.or-divider {
    color: #D6D6D6;
}
.otp-verification-section .verification_code label {
    font-size: 12px;
    text-align: center;
}

@media only screen and (max-width: 768px){
    .Already-have-an-accout-wrapper {
        width: 90%;
        text-align: center;
        margin: 0px auto;
    }
    .Already-have-an-accout-wrapper .terms-and-Privacy-policy {
        font-size: 12px;
    }
    .user-tab-selecting-folder, 
    .registe-emai-verfication-section .signup-form, 
    .otp-verification-section .verification_code  {
        width: 100%;
    }
}

@media only screen and (max-width: 767px){
    .login-mani-wrapper {
        display: flex;
        flex-direction: column;
    }
    .register-left-section, 
    .user-tab-selecting-folder , 
    .otp-verification-section .verification_code {
        width: 100%;
    }
    .register-right-section {
        display: none;
    }
    .Register-container-wrapper h2, 
    .Register-container-wrapper h2 span  {
        font-size: 23px;
    }
    .Already-have-an-accout-wrapper {
        width: 90%;
        text-align: center;
        margin: 0px auto;
    }
    .login-with-Verification-code p {
        font-size: 14px;
    }
    .registe-emai-verfication-section .signup-form {
        width: 100%;
        margin: 0px auto;
    }
    .Remaning_timer, 
    .resend-verification-with-edit-email-folder button  {
        font-size: 12px !important;
    }
}