.Congratulations-Popup-folder {
    max-width: 718px;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 47px 36px;
    background-color: white;
    border-radius: 17px;
    position: fixed;
    top: 50%;
    left: 0%;
    right: 0%;
    transform: translateY(-50%);
}

.Congratulations-Popup-folder {
    display: flex;
    flex-direction: column;
}

.Congratulations-Popup-folder h2 {
    font-size: 25px;
    margin: 6px auto 0px auto !important;
    max-width: 477px;
}

.check-image {
    width: 70px;
    height: 70px;
    margin: 0px auto;
}

.Congratulations-Popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 120;
    background-color: rgba(0, 0, 0, 0.469);
}

@media only screen and (max-width: 767px){
    .Congratulations-Popup-folder {
        width: 315px;
        padding: 23px 26px;
    }
    .Congratulations-Popup-folder .check-image {
        width: 53px;
        height: 53px;
        margin: 0px auto;
    }
    .Congratulations-Popup-folder h2 {
        font-size: 18px;
        max-width: 477px;
    }
}