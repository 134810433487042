@media only screen and (max-width: 767px) {
    .edit-active-lesson-btn-container button {
        padding: 2px 11px !important;
        font-size: 10px !important;
    }
    .toolbar button.toolbar-item i.format {
        background-size: contain;
        display: inline-block;
        height: 12px;
        width: 12px;
    }
    .toolbar button.toolbar-item{
        padding: 0px !important;
    }
    .toolbar i.chevron-down {
        margin-top: 3px;
        width: 11px;
        height: 11px;
    }
    .toolbar .toolbar-item .text{
        width: 60px !important;
        font-size: 10px !important;
    }
    .editor-placeholder, .all-editer-tools-section span {
        font-size: 14px !important;
    }
    .all-editer-tools-section {
        display: flex;
        gap: 9px;
        align-items: baseline;
    }
    .personlized_popup{
        width: 100%;
    }
    .edit-active-lesson-btn-container {
        display: flex;
        gap: 9px;
    }
    .lesson_generator_inner {
        margin-bottom: 6px;
    }
    .download-popup h2{
        font-size: 14px;
    }
    .download-popup {
        width: 90%;
    }
    button.pdf-btn, button.spreadsheet-btn {
        padding: 7px 9px;
        font-size: 10px !important;
    }
    .spreadsheet-btn i, 
    .pdf-btn i {
        font-size: 13px;
    }
    .chat-with-ai-lession-user-sned-massege-container{
        padding: 6px 20px;
    }
    .send-user-name h2 {
        font-size: 12px;
        margin: 0px;
        width: 26px;
        height: 26px;
    }
    .loading-chat-answer p,.loading-chat-answer ol li{
        font-size: 12px !important;
    }
    .create-all-btns-container{
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .create-all-btns-container button{
        width: 100% !important;
    }
    
    .create-all-btns {
        width: 100%;
        margin: 0 auto;
    }
    .proc_btn_new{
        margin-top: 4px;
        font-size: 12px;
    }
    .create-all-btns button{
        font-size: 12px !important;
        padding: 5px 0px;
    }
}