.aboutUs-conatiner {
    display: flex;
    width: 100%;
    margin: 50px 0px;
}
.aboutUs-left-section {
    width: 50%;
}
.aboutUs-left-section-image img{
    width: 100%;
}
.aboutUs-left-section-image {
    width: 253px;
}
.aboutUs-left-section h2{
    font-size: 24px;
    margin: 31px 0px 22px 0px;
    line-height: 31px;
}
.aboutUs-left-section p{
    font-size: 15px;
    color: black;
    line-height: 24px;
}
.aboutUs-right-section {
    width: 50%;
    display: flex;
    justify-content: center;
}
.aboutUs-right-section-image, 
.aboutUs-for-teacher-right-section-image {
    width: 430px;
}
.aboutUs-right-section-image img, 
.aboutUs-for-teacher-right-section-image img{
    width: 100%;
}
.aboutUs-for-teacher-conatiner, 
.aboutUs-for-student-conatiner, 
.aboutUs-for-parent-conatiner {
    display: flex;
    width: 100%;
    margin: 30px 0px 50px 0px;
    align-items: center;
}
.aboutUs-for-teacher-right-section {
    width: 40%;
    display: flex;
    justify-content: center;
}
.aboutUs-for-aboutUs-for-student-left-section-left-section {
    width: 60%;
    position: relative;
}
.aboutUs-for-teacher-list-container, 
.aboutUs-for-student-list-container, .aboutUs-for-parent-list-container {
    padding: 18px;
    border-radius: 14px;
    background-color: #EBF3FF;
    display: flex;
    flex-direction: column;
    gap: 13px;
    box-shadow: 0px 2px 8px #dddddd;
}
.aboutUs-for-teacher-list-list-folder , 
.aboutUs-for-student-list-list-folder, 
.aboutUs-for-parent-list-list-folder{
    display: flex;
    align-items: center;
    gap: 10px;
}
.aboutUs-for-teacher-list-list-folder p, 
.aboutUs-for-student-list-list-folder p, 
.aboutUs-for-parent-list-list-folder p{
    font-size: 15px;
    color: black;
    font-weight: 500;
}
.aboutUs-aboutus-teacher-logo-image {
    width: 276px;
}
.aboutUs-logo-image-folder {
    background: linear-gradient(90deg, #AFD0FF 0.19%, rgba(217, 217, 217, 0.00) 116.91%);
    width: 371px;
    display: flex;
    justify-content: center;
    margin-left: -60px;
}
.aboutUs-for-student-wrapper .aboutUs-logo-image-folder{
    margin-left: auto;
}
.aboutUs-aboutus-teacher-logo-image img{
    width: 100%;
}
.aboutUs-for-teacher-book-image {
    position: absolute;
    top: -18%;
    right: 0%;
}
.aboutUs-for-teacher-book-image img{
    width: 97px;
}
.aboutUs-for-teacher-left-section, 
.aboutUs-for-student-left-section , 
.aboutUs-for-parent-left-section{
    width: 60%;
    position: relative;
}
.aboutUs-for-student-book-image {
    position: absolute;
    top: -15%;
    left: 2%;
}
.aboutUs-for-parent-book-image {
    position: absolute;
    top: -15%;
    right: 2%;
}
.aboutUs-for-student-book-image img, 
.aboutUs-for-parent-book-image img{
    width: 70px;
}
.aboutUs-for-student-book-image {
    position: absolute;
    top: -15%;
    left: 2%;
}
.aboutUs-our-vision-container {
    display: flex;
    width: 100%;
    margin: 37px 0px 0px 0px;
    justify-content: space-between;
}
.aboutUs-our-vision-folder {
    border: 1px solid;
    padding: 52px 37px;
    width: 40%;
    background-color: #0068FF;
    color: white;
    border-radius: 0px 20px 20px 0px;
    position: relative;
}
.aboutUs-our-vision-folder:nth-child(2) {
    border: none;
    background-color: white;
    color: #0068FF;
    border-radius: 20px 0px 0px 20px;
    box-shadow: 0px 2px 8px #dddddd;
    position: relative;
    width: 42%;
}
.aboutUs-our-vision-folder:nth-child(2) p{
    color: black;
}
.aboutUs-our-vision-folder h2{
    font-size: 32px;
}
.aboutUs-our-vision-folder p{
    color: white;
    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
}
.aboutus_our_Vision_image {
    position: absolute;
    top: -20%;
    right: -24%;
}
.aboutus_our_Vision_image2{
    position: absolute !important;
    top: 0% !important;
    left: -24% !important;
}
.aboutus_our_Vision_image2 {
    position: absolute;
    top: -20% !important;
    left: -102px !important;
}
.Educational-Revolution-conatiner {
    margin: 70px 0px;
    position: relative;
}
.Educational-Revolution-conatiner h2 {
    color: #002469;
    font-size: 30px;
    margin-bottom: 52px;
}
.Educational-Revolution-inner-conatiner {
    box-shadow: 0px 2px 10px #b9b9b9;
    border-radius: 15px;
}
.Educational-Revolution-inner-conatiner p {
    font-size: 15px;
    color: black;
    padding: 16px;
    font-weight: 500;
}
.aboutus_Educational-Revolution_image img{
    width: 100%;
}
.aboutus_Educational-Revolution_image-bg {
    position: absolute;
    top: 7%;
    left: 0px;
}
.aboutus_Educational-Revolution_image-bg img{
    width: 508px;
}
.aboutus_Educational-Revolution_image-bg2 {
    position: absolute;
    top: -5%;
    right: -25px;
}