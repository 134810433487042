.google-drive-popup-container {
    padding: 18px;
    box-shadow: 0px 2px 8px #dbdbdb;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .google-drive-popup-folder {
    display: flex;
    align-items: center;
    gap: 11px;
  }
  .google-drive-image {
    width: 19px;
    height: 21px;
  }
  .google-drive-image img{
    width: 100%;
  }
  .google-drive-popup-folder p{
    color: black;
    font-size: 13px;
  }
  .google-drive-popup-wrapper {
    position: absolute;
    top: -69px;
    z-index: 113;
  }