.add-to-cart-lesson-title{
    font-size: 20px;
    margin: 20px 0px;
}
.lesson-container {
    padding: 33px;
    box-shadow: 0px 1px 7px #cfcfcf;
    margin-bottom: 30px;
    border-radius: 7px;
}
.lesson-folder {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    gap: 24px;
}
.lesson-duration, 
.assignments-count, 
.Lesson-des{
    display: flex;
    align-items: center;
    gap: 8px;
    color: black;
    font-size: 13px;
}
.Lesson-des {
    width: 292px;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.lesson-dropdown-tab h2{
    font-size: 16px;
    margin: 0px 0px 0px 0px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.lesson-dropdown-tab{
    gap: 10px;
    align-items: center;
}
.lesson-inner-assignments-container {
    border: 1px solid #e7e7e7;
    padding: 28px;
    border-radius: 10px;
    margin: 30px 0px 0px 0px;
}
.lesson-inner-assignments-container h2{
    border-bottom: 1px solid;
    margin: 0px 0px 20px 0px;
    padding-bottom: 28px;
    font-size: 18px;
    font-weight: 400;
}
.lesson-inner-assignments-text-feild h2 {
    font-size: 14px;
    margin: 0px 0px 6px 0px;
    border: none;
    padding: 0px;
    font-weight: 500;
}
.lesson-inner-assignments-text-feild p {
    font-size: 13px;
    color: black;
}