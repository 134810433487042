.table-container {
    width: 100%;
    border-collapse: collapse;
  }
  
.table-container th,
.table-container td {
    text-align: left;
    padding: 16px;
    text-align: center;
    font-size: 13px;
}
.table-row {
    background-color: #F5FBFF;
    box-shadow: 0px 2px 5px #e1e1e187;
}

.header-cell {
    font-weight: bold;
}

.checkbox-cell {
    width: 50px; /* Adjust width as necessary */
}

.name-cell {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 16px;
}

.action-cell {
    cursor: pointer;
    color: #1890ff;
    text-align: center;
}

.dashboard-name-title {
    margin-bottom: 20px;
}
#Student_Review_Table_Data thead > tr > th input {
    width: 14px;
    height: 14px;
}
.student-shortby-popup .sort-popup-assignprocess {
    position: absolute;
    right: 446px;
    margin-top: 20px;
}
.table-row {
    background-color: #F5FBFF;
    box-shadow: 0px 1px 7px #d9d9d987;
}
.table_blank_row{
    margin-bottom: 20px;
}