.topratingreview-wrapper {
    display: flex;
    width: 100%;
    gap: 18px;
    margin: 50px 0px 20px 0px;
}
.topratingreview-left-section {
    width: 20%;
    text-align: center;
    padding: 15px;
    box-shadow: 0 1px 8px #d5d5d5bd;
    height: 326px;
    position: sticky;
    top: 99px;
}
.topratingreview-title{
    font-size: 16px;
    color: black;
    font-weight: 600;
}
.topratingreview-left-section h2{
    font-size: 25px;
    font-weight: 600;
    margin: 13px 0px 10px 0px;
}
.topratingreview-stars {
    display: flex;
    justify-content: center;
    gap: 9px;
}
.topratingreview-stars span {
    color: #FAAF19;
    font-size: 14px;
}
.topratingreview-progress-bar-folder {
    display: flex;
    justify-content: center;
    gap: 7px;
}
.topratingreview-progress-bar-folder p{
    color: black;
    font-size: 13px;
}
.rating-numbers p{
    color: black;
    margin: 5px 0px 15px 0px;
}
.topratingreview-right-section{
    width: 80%;
}
.topratingreview-right-section h2{
    font-size: 17px;
}
.send-message-icon {
    position: absolute;
    top: 11px;
    right: 13px;
}
.topratingreview-card-folder{
    padding: 20px;
    box-shadow: 0 1px 8px #d5d5d5bd;
}
.topratingreview-card-folder .topratingreview-stars{
    justify-content: flex-start;
}
.topratingreview-card-inner-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 10px 0px;
}
.topratingreview-card-inner-profile-folder {
    display: flex;
    align-items: center;
    gap: 13px;
}
.topratingreview-card-inner-profile {
    width: 45px;
    height: 45px;
}
.topratingreview-card-inner-profile img{
    width: 100%;
}
.topratingreview-card-profile-title{
    color: black;
    font-weight: 500;
    font-size: 13px;
}
.topratingreview_btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 27px;
}
.topratingreview-reply-btn{
    font-size: 13px;
    border: none;
    padding: 6px 24px;
    background-color: transparent;
    box-shadow: 0px 0px 5px #c7c7c7ad;
    border-radius: 6px;
}
.topratingreview-reply-btn i{
    font-weight: 600;
    margin: 0px 5px 0px 0px;
}
.topratingreview_btn-container span{
    color: #FF6363;
}
.topratingreview-card-des-folder {
    margin: 10px 0px 15px 0px;
}
.topratingreview-card-des-folder p{
    font-size: 12px;
    color: black;
    line-height: 22px;
}
.topratingreview-card-massege-wrapper {
    display: flex;
    align-items: center;
    gap: 19px;
    position: relative;
}
.topratingreview-card-inner-profile {
    width: 45px;
    height: 45px;
}
.topratingreview-card-inner-profile img{
    width: 100%;
}
.topratingreview-card-massege-wrapper textarea{
    width: 100%;
    padding: 9px;
    height: 37px;
    font-size: 13px;
    background-color: #FCF9F9;
    border: none;
    resize: none;
    color: black;
}
.topratingreview-card-massege-edit-folder, 
.topratingreview-card-date-folder {
    display: flex;
    justify-content: flex-end;
}
.topratingreview-card-massege-edit-folder button{
    border: none;
    background-color: transparent;
    font-size: 13px;
    color: #0068FF;
}
.topratingreview-card-massege-edit-folder button:nth-child(2){
    color: #FF6363;
}
.topratingreview-card-date-folder span{
    font-size: 11px;
    color: #7D7B7B;
}
.topratingreview-card-massege-edit-folder{
    margin: 10px 0px;
    gap: 12px;
}
.topratingreview-progress-bar-folder .css-8atqhb {
    width: 77%;
}
.topratingreview-progress-bar-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.topratingreview-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}