#loading-bar-spinner.spinner {
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -15px;
    position: absolute;
    z-index: 19 !important;
    animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
    width: 28px;
    height: 28px;
    border: solid 4px transparent;
    border-top-color: #00C8B1 !important;
    border-left-color: #00C8B1 !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}