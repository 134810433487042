.drawing-canvas-popup {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-color: #00000042;
    z-index: 113;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drawing-canvas-popup-wrapper {
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 16px;
}
.convert-drawing {
    padding: 10px 0px;
    color: white;
    background-color: #0068FF;
    font-size: 12px;
    border: none;
    border-radius: 5px;
}
.drawing-canvas-popup-button-folde{
    width: 100%;
    width: 100%;
    gap: 10px;
    display: flex;
}
.drawing-canvas-popup-button-folde button{
    width: 50%;
}
.drawing-canvas-popup-button-folde button:nth-child(1){
    background-color: black;
}
.drawing-canvas-popup-button-folde button:nth-child(2){
    border: 2px solid #0068FF;
    color: #0068FF;
    background-color: transparent;
    font-weight: 500;
}
.drawing-canvas-popup-section{
    width: 600px !important;
    height: 600px !important;
}

@media only screen and (max-width: 767px){
    .drawing-canvas-popup-wrapper {
        display: flex;
        flex-direction: column;
        width: 314px;
        gap: 16px;
    }
    .drawing-canvas-popup-section {
        width: 100% !important;
        height: 460px !important;
    }
}

@media (min-width: 280px) and (max-width: 320px){
    .drawing-canvas-popup-wrapper {
        width: 259px;
    }
    .convert-drawing {
        padding: 7px 0px;
        font-size: 10px;
    }
    .assignment-uplode-wrapper {
        margin: 0px 0px 10px 0px;
    }
    .UplodeFile-folder {
        padding: 8px 4px;
        width: 116px;
    }
}